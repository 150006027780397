import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Pagination } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, query, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import './ArticlesDisplay.css';
import { useAuth } from '../context/AuthContext';
import { deleteDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const ArticlesDisplay = () => {
    const [articles, setArticles] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { userProfile } = useAuth();
    const isAdmin = userProfile && userProfile.role === 'admin';
    const PAGE_LIMIT = 3;

    const fetchArticles = useCallback(async (page) => {
        let q = query(
            collection(db, 'articles'),
            orderBy('publishDate', 'desc'),
            limit(PAGE_LIMIT)
        );

        if (page > 1 && lastVisible) {
            q = query(q, startAfter(lastVisible));
        }

        const querySnapshot = await getDocs(q);
        const fetchedArticles = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const dateISO = new Date(data.publishDate.seconds * 1000);
            fetchedArticles.push({ id: doc.id, ...data, publishDate: dateISO });
        });

        setArticles(fetchedArticles);
        if (!querySnapshot.empty) {
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
    }, [lastVisible]);

    useEffect(() => {
        const fetchTotalArticlesCount = async () => {
            const articlesCollection = collection(db, 'articles');
            const articlesSnapshot = await getDocs(articlesCollection);
            const total = articlesSnapshot.size;
            setTotalPages(Math.ceil(total / PAGE_LIMIT));
        };

        fetchTotalArticlesCount();
        fetchArticles(currentPage);
    }, [currentPage, fetchArticles]);

    const handlePaginationClick = (newPageNumber) => {
        setCurrentPage(newPageNumber);
    };

    const createMarkup = (html) => {
        return { __html: html };
    };

    const handleDeleteArticle = async (articleId) => {
        if (!isAdmin || !window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) {
            return;
        }
        await deleteDoc(doc(db, "articles", articleId));
        setArticles(articles.filter(article => article.id !== articleId));
    };

    return (
        <div className="articles-display-container">
            <header className="articles-display-header">
                <h1>Actualités de Xavier</h1>
                <p>
                    Découvrez les dernières actualités et performances marquantes de Xavier Flabat. Restez informé et
                    plongez dans l'univers musical du ténor d'opéra international.
                </p>
            </header>
            <div className="articles-display-grid">
                {articles.map((article) => (
                    <Card key={article.id} className="articles-display-card">
                        <Card.Img variant="top" src={article.coverImage} className="articles-display-image" />
                        <Card.Body>
                            <div className="articles-display-meta">
                                <span className="articles-display-author">Par {article.author}</span>
                                <span className="articles-display-date">{article.publishDate.toLocaleDateString()}</span>
                            </div>
                            <Card.Title>{article.title}</Card.Title>
                            <Card.Text dangerouslySetInnerHTML={createMarkup(article.content.substring(0, 200))} />
                            <div className="articles-display-actions">
                                <Button variant="primary" href={`/articles/${article.id}`}>
                                    Lire l'article
                                </Button>
                                {isAdmin && (
                                    <>
                                        <Link to={`/articles/edit/${article.id}`}>
                                            <Button variant="secondary">Modifier</Button>
                                        </Link>
                                        <Button
                                            variant="danger"
                                            onClick={() => handleDeleteArticle(article.id)}
                                        >
                                            Supprimer
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </div>
            <Pagination className="articles-display-pagination">
                <Pagination.First onClick={() => handlePaginationClick(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />
                {Array.from({ length: totalPages }, (_, i) => (
                    <Pagination.Item
                        key={i + 1}
                        active={i + 1 === currentPage}
                        onClick={() => handlePaginationClick(i + 1)}
                    >
                        {i + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => handlePaginationClick(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
        </div>
    );
};

export default ArticlesDisplay;
