// src/components/Navbar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar as BootstrapNavbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUser, faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';
import './Navbar.css';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ onSignupClick, onSignInClick, isAdmin }) => {
    const { currentUser, userProfile, logout } = useAuth();
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error("Logout failed: ", error);
        }
    };

    return (
        <BootstrapNavbar
            className={`navbar-opera ${isScrolled ? 'scrolled' : ''}`}
            expand="lg"
            fixed="top"
        >
            <Container>
                <BootstrapNavbar.Brand as={Link} to="/">
                    <img
                        src={logo}
                        height="50"
                        className="d-inline-block align-top"
                        alt="Xavier Opéra Logo"
                    />
                </BootstrapNavbar.Brand>
                <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
                <BootstrapNavbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto nav-center">
                        <Nav.Link as={Link} to="/">Accueil</Nav.Link>
                        <Nav.Link as={Link} to="/performances">Agenda</Nav.Link>
                        <Nav.Link as={Link} to="/biography">Biographie</Nav.Link>
                        <Nav.Link as={Link} to="/artists">Artistes</Nav.Link>
                        <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                        {isAdmin && <Nav.Link as={Link} to="/admin">Dashboard Admin</Nav.Link>}
                    </Nav>

                    <Nav>
                        {currentUser ? (
                            <NavDropdown
                                title={<><FontAwesomeIcon icon={faUser} /> {userProfile.pseudo || currentUser.email}</>}
                                id="basic-nav-dropdown"
                                className="nav-dropdown"
                            >
                                <NavDropdown.Item as={Link} to="/profile" className="dropdown-item-custom">
                                    <FontAwesomeIcon icon={faUserCircle} className="dropdown-item-icon" /> Profil
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout} className="dropdown-item-custom">
                                    <FontAwesomeIcon icon={faSignOutAlt} className="dropdown-item-icon" /> Déconnexion
                                </NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            <Nav.Link onClick={onSignInClick}>
                                <FontAwesomeIcon icon={faSignInAlt} />
                            </Nav.Link>
                        )}
                    </Nav>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    );
};

export default Navbar;
