// Biography.js
import React, { useEffect } from 'react';
import './Biography.css';
import xavierImage from '../assets/XF.jpg';

const Biography = () => {
  
  useEffect(() => {
    // Gestion simple de l'apparition des éléments de la frise chronologique au scroll
    const items = document.querySelectorAll('.timeline-item');
    const onScroll = () => {
      items.forEach(item => {
        const rect = item.getBoundingClientRect();
        if (rect.top < window.innerHeight - 100) {
          item.classList.add('visible');
        }
      });
    };
    window.addEventListener('scroll', onScroll);
    onScroll(); // Vérifier dès le chargement
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className="biography-container">
      <header className="biography-header">
        <h1 className="biography-title">Biographie</h1>
      </header>
      <div className="biography-content">
        <div className="biography-image-container">
          <img src={xavierImage} alt="Xavier Flabat" className="biography-image" />
        </div>
        <div className="biography-text">
          <p>
            Xavier Flabat commence sa formation vocale au Conservatoire de Lille. Il suit ensuite les enseignements de la mezzo-soprano Christine Solhosse et du ténor Thierry Dran. Actuellement, Xavier se perfectionne auprès de la mezzo-soprano Mariam Sarkissian, à Paris et du baryton Jeremy Carpenter, à Stockholm.
          </p>
          <p>
            À l’Opéra Royal de Wallonie, à Liège, il incarne le Prince Charmant dans <em>Cendrillon</em> de Pauline Viardot, et tout récemment, Hermosa dans <em>L’île de Tulipatan</em> d’Offenbach. Passionné par le répertoire français, il interprète Faust et recrée le rôle du contrebandier Scopetto dans <em>La Sirène</em> d’Auber au Théâtre Impérial de Compiègne. Il est encore le chevalier Des Grieux dans <em>Manon</em> de Massenet. Au Théâtre de Reims, il tient le rôle d’Octave, le Magnifique, dans l’opéra éponyme de Grétry.
          </p>
          <p>
            Il endossait, dernièrement, le rôle de Don José dans <em>Carmen</em> de Bizet au Festival de Toûno en Suisse et celui d’Hoffmann dans <em>Les Contes d’Hoffmann</em> d’Offenbach. Ces deux dernières saisons, il créait le rôle de Georges Remus dans <em>Eden Park</em> de Gérard Massini, celui de Doute Onirique dans <em>Le Métronome de nos errances</em> de Stéphane Leach et actuellement, celui d’Ethan dans <em>Erotikos Factory</em> de Gérard Massini.
          </p>
          <p>
            Il est aussi régulièrement invité par des formations orchestrales telles que l’Orchestre du Val de Sambre, l’Orchestre Philharmonique Royal de Liège, et celui de Bruxelles. Cela lui permet de prendre part à des pièces de Rossini, telles que le <em>Stabat Mater</em> ou la <em>Petite Messe Solennelle</em>.
          </p>
          <p>
            L’opérette lui est également familière avec des rôles comme Paganini dans <em>Paganini</em> de Léhar, Sou-Chong dans <em>Le Pays du Sourire</em>, et Piquillo dans <em>La Périchole</em>.
          </p>
        </div>
      </div>

      {/* Section de frise chronologique */}
      <div className="biography-timeline">
        <div className="timeline-item">
          <h2>Formation et Débuts</h2>
          <p>
            Xavier commence sa formation vocale au Conservatoire de Lille, sous la direction de Christine Solhosse et Thierry Dran, avant de se perfectionner avec Mariam Sarkissian et Jeremy Carpenter.
          </p>
        </div>
        <div className="timeline-item">
          <h2>Premières grandes scènes</h2>
          <p>
            À l’Opéra Royal de Wallonie, Xavier interprète le Prince Charmant dans <em>Cendrillon</em> et Hermosa dans <em>L’île de Tulipatan</em>, marquant ses premiers rôles majeurs sur scène.
          </p>
        </div>
        <div className="timeline-item">
          <h2>Répertoire français</h2>
          <p>
            Il s’illustre dans des opéras français comme <em>Faust</em>, <em>Manon</em> (rôle de Des Grieux) et <em>La Sirène</em> (Scopetto) au Théâtre Impérial de Compiègne.
          </p>
        </div>
        <div className="timeline-item">
          <h2>Collaborations contemporaines</h2>
          <p>
            Ces dernières années, Xavier a créé des rôles dans <em>Eden Park</em>, <em>Le Métronome de nos errances</em>, et <em>Erotikos Factory</em>, des œuvres modernes innovantes.
          </p>
        </div>
        <div className="timeline-item">
          <h2>Invité régulier d'orchestres</h2>
          <p>
            Invité par de prestigieux orchestres européens, il interprète des œuvres comme le <em>Stabat Mater</em> et la <em>Petite Messe Solennelle</em> de Rossini.
          </p>
        </div>
        <div className="timeline-item">
          <h2>Passion pour l'opérette</h2>
          <p>
            L’opérette occupe également une place importante dans sa carrière, avec des rôles comme Paganini, Sou-Chong, et Piquillo.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Biography;
