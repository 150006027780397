// src/components/Contact.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import './Contact.css';
import backgroundImage from '../assets/contact.webp'; // Ajustez le chemin si nécessaire

function Contact() {
    const { currentUser, userProfile } = useAuth();
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleSendEmail = (e) => {
        e.preventDefault();

        // Vérifier si l'utilisateur est connecté
        if (!currentUser) {
            setShowAlert(true);
            return;
        }

        // Préparer les détails de l'email
        const userEmail = currentUser.email;
        const subject = encodeURIComponent(`Nouveau message de ${userProfile?.pseudo || userEmail}`);
        const body = encodeURIComponent(message);

        // Ouvrir le client mail par défaut
        window.open(`mailto:xavier_flabat@hotmail.com?subject=${subject}&body=${body}`);
    };

    return (
        <Container
            fluid
            className="contact-container"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            {/* Overlay pour l'effet premium */}
            <div className="contact-overlay"></div>
            <Row className="justify-content-md-center">
                <Col md={12} className="contact-form-container">
                    <h2 className="contact-title">Voulez-vous contacter Xavier ?</h2>
                    <p className="contact-description">
                        Écrivez-lui un message
                        {userProfile?.pseudo ? `, ${userProfile.pseudo}` : ''}.
                    </p>
                    <Form onSubmit={handleSendEmail} className="contact-form">
                        <Form.Group controlId="formBasicMessage" className="contact-form-group">
                            <Form.Label className="contact-form-label">Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Votre message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="contact-form-control"
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="contact-submit-button">
                            Envoyer
                        </Button>
                    </Form>

                    {showAlert && (
                        <Alert
                            variant="danger"
                            onClose={() => setShowAlert(false)}
                            dismissible
                            className="contact-alert"
                        >
                            Vous devez être connecté pour envoyer un message.
                        </Alert>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default Contact;
