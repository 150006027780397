import React, { useState } from 'react'; // Import useState here
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import SignupModal from './components/SignupModal';
import SignInModal from './components/SignInModal';
import Profile from './components/Profile';
import AdminDashboard from './components/AdminDashboard';
import { AuthProvider, useAuth } from './context/AuthContext';
import PerformanceList from './components/PerformanceList';
import PerformanceDetail from './components/PerformanceDetail';
import Contact from './components/Contact';
import ArticleEdit from './components/ArticleEdit';
import ArticlesDisplay from './components/ArticlesDisplay';
import ArticleDetails from './components/ArticleDetails'; // Import the new component
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import Artists from './components/Artists';
import CookieConsent from "react-cookie-consent"; // Importez le composant CookieConsent
import Biography from './components/Biography'; // Import the new component

function App() {
  return (
    <AuthProvider> {/* All components inside here can use useAuth */}
      <Router>
        <Main />
      </Router>
    </AuthProvider>
  );
}

function Main() {
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { userProfile } = useAuth(); // useAuth is now called within a component that is a child of AuthProvider
  const isAdmin = userProfile && userProfile.role === 'admin';

  const handleShowSignupModal = () => setShowSignupModal(true);
  const handleCloseSignupModal = () => setShowSignupModal(false);
  const handleShowSignInModal = () => setShowSignInModal(true);
  const handleCloseSignInModal = () => setShowSignInModal(false);

  return (
    <>
      <Navbar onSignupClick={handleShowSignupModal} onSignInClick={handleShowSignInModal} isAdmin={isAdmin} />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/performances" element={<PerformanceList />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/performances/:performanceId" element={<PerformanceDetail />} />
          <Route path="/admin" element={isAdmin ? <AdminDashboard /> : <Navigate to="/" />} />
          <Route path="/articles/edit/:id" element={<ArticleEdit />} />
          <Route path="/articles/:articleId" element={<ArticleDetails />} />
          <Route path="/" element={<ArticlesDisplay />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/biography" element={<Biography />} />
        </Routes>


      </div>
      <SignupModal show={showSignupModal} handleClose={handleCloseSignupModal} />
      <SignInModal show={showSignInModal} handleClose={handleCloseSignInModal} />
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        cookieName="userConsentCookies"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Nous utilisons des cookies pour améliorer votre expérience. En continuant à utiliser ce site, vous acceptez notre politique de cookies.
      </CookieConsent>
    </>
  );
}

export default App;
