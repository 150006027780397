// src/components/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table, Button, Pagination, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, query, getDocs, limit, startAfter, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FaTrash, FaEdit, FaPlusCircle } from 'react-icons/fa';
import ArticleForm from './ArticleForm'; // Importation du formulaire d'article
import './AdminDashboard.css';
const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newRole, setNewRole] = useState('');
  const [showArticleForm, setShowArticleForm] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const q = query(collection(db, "users"), limit(15));
        const querySnapshot = await getDocs(q);
        const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersData);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setLoading(false);
      } catch (err) {
        setError("Erreur lors du chargement des utilisateurs.");
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleNext = async () => {
    try {
      setLoading(true);
      const nextQuery = query(collection(db, "users"), startAfter(lastVisible), limit(15));
      const querySnapshot = await getDocs(nextQuery);
      const nextUsers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(nextUsers);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setLoading(false);
    } catch (err) {
      setError("Erreur lors du chargement des utilisateurs suivants.");
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")) {
      try {
        await deleteDoc(doc(db, "users", userId));
        setUsers(users.filter(user => user.id !== userId));
      } catch (err) {
        setError("Erreur lors de la suppression de l'utilisateur.");
      }
    }
  };

  const handleOpenChangeRoleModal = (user) => {
    setSelectedUser(user);
    setNewRole(user.role);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleChangeRole = async () => {
    try {
      await updateDoc(doc(db, "users", selectedUser.id), { role: newRole });
      setUsers(users.map(user => user.id === selectedUser.id ? { ...user, role: newRole } : user));
      setShowConfirmModal(false);
    } catch (err) {
      setError("Erreur lors de la mise à jour du rôle.");
    }
  };

  const toggleArticleForm = () => setShowArticleForm(!showArticleForm);

  return (
    <Container className="admin-dashboard-container">
      <Row className="justify-content-md-center">
        <Col md={10} className="admin-dashboard-col">
          <Card className="admin-dashboard-card">
            <Card.Body>
              <h2 className="text-center mb-4 admin-dashboard-title">Tableau de bord Admin</h2>
              {error && <Alert variant="danger" className="admin-dashboard-error">{error}</Alert>}

              <div className="d-flex justify-content-end mb-3 admin-dashboard-button-container">
                <Button variant="primary" onClick={toggleArticleForm} className="admin-dashboard-create-button">
                  <FaPlusCircle /> Créer un article
                </Button>
              </div>

              <Table responsive bordered hover className="admin-dashboard-table">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Pseudo</th>
                    <th>Rôle</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id} className="admin-dashboard-row-item">
                      <td>{user.email}</td>
                      <td>{user.pseudo}</td>
                      <td>{user.role}</td>
                      <td>
                        <Button
                          variant="info"
                          size="sm"
                          className="me-2 admin-dashboard-edit-button"
                          onClick={() => handleOpenChangeRoleModal(user)}
                        >
                          <FaEdit /> Modifier
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          className="admin-dashboard-delete-button"
                          onClick={() => handleDeleteUser(user.id)}
                        >
                          <FaTrash /> Supprimer
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {loading ? (
                <div className="text-center my-3 admin-dashboard-spinner-container">
                  <Spinner animation="border" className="admin-dashboard-spinner" />
                </div>
              ) : (
                <Pagination className="justify-content-center admin-dashboard-pagination">
                  <Pagination.Next onClick={handleNext} disabled={!lastVisible} />
                </Pagination>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal de modification de rôle */}
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} className="admin-dashboard-modal">
        <Modal.Header closeButton>
          <Modal.Title className="admin-dashboard-modal-title">Modifier le rôle</Modal.Title>
        </Modal.Header>
        <Modal.Body className="admin-dashboard-modal-body">
          <Form.Group>
            <Form.Label>
              Choisir un nouveau rôle pour {selectedUser?.email}
            </Form.Label>
            <Form.Select
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
              className="admin-dashboard-role-select"
            >
              <option value="user">Utilisateur</option>
              <option value="admin">Administrateur</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="admin-dashboard-modal-footer">
          <Button variant="secondary" onClick={handleCloseConfirmModal} className="admin-dashboard-modal-cancel">
            Annuler
          </Button>
          <Button variant="primary" onClick={handleChangeRole} className="admin-dashboard-modal-confirm">
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de création d'article */}
      <Modal show={showArticleForm} onHide={toggleArticleForm} size="lg" className="admin-dashboard-modal">
        <Modal.Header closeButton>
          <Modal.Title className="admin-dashboard-modal-title">Créer un article</Modal.Title>
        </Modal.Header>
        <Modal.Body className="admin-dashboard-modal-body">
          <ArticleForm />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AdminDashboard;
