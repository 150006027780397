import React from 'react';
import { Container } from 'react-bootstrap';
import { FaBalanceScale, FaEdit, FaQuestionCircle, FaRegHandshake } from 'react-icons/fa';
import './TermsConditions.css';

const TermsConditions = () => {
    return (
        <Container fluid className="terms-conditions-container">
            <h1 className="terms-conditions-title">
                <FaBalanceScale className="terms-icon" /> Termes et Conditions
            </h1>
            <p className="terms-conditions-date">Dernière mise à jour : 02/04/2024</p>

            <section className="terms-section">
                <h2 className="terms-section-title">
                    <FaRegHandshake className="terms-icon" /> Utilisation du Service
                </h2>
                <p>
                    En accédant au site web <a href="https://xavier-tenor-opera.web.app/" className="terms-link">xavier-tenor-opera.web.app</a>, 
                    vous acceptez d'être lié par ces termes et conditions, ainsi que par toutes les lois et réglementations applicables, 
                    et vous acceptez que vous êtes responsable du respect des lois locales applicables. Si vous n'êtes pas d'accord avec 
                    l'un de ces termes, vous êtes interdit d'utiliser ou d'accéder à ce site.
                </p>
            </section>

            <section className="terms-section">
                <h2 className="terms-section-title">
                    <FaEdit className="terms-icon" /> Modifications
                </h2>
                <p>
                    Nous nous réservons le droit de modifier ou de remplacer ces termes à tout moment. Tous les changements entreront en 
                    vigueur immédiatement après leur publication sur notre site Web. Votre utilisation continue de notre site après de telles 
                    modifications constituera votre reconnaissance et acceptation des termes et conditions modifiés.
                </p>
            </section>

            <section className="terms-section">
                <h2 className="terms-section-title">
                    <FaQuestionCircle className="terms-icon" /> Contactez-Nous
                </h2>
                <p>
                    Si vous avez des questions sur ces Termes et Conditions, veuillez nous contacter via email à l'adresse 
                    <a href="mailto:xavier_flabat@hotmail.com" className="terms-link"> xavier_flabat@hotmail.com</a>.
                </p>
            </section>
        </Container>
    );
};

export default TermsConditions;
