import React, { useState, useEffect } from 'react';
import { Carousel, Container, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './FeaturedArtists.css';
import { FaInstagramSquare, FaYoutubeSquare, FaGlobeAmericas } from 'react-icons/fa';

const FeaturedArtists = () => {
    const [featuredArtists, setFeaturedArtists] = useState([]);
    const [chunkSize, setChunkSize] = useState(3);

    useEffect(() => {
        const fetchArtists = async () => {
            const artistsCollectionRef = collection(db, 'artists');
            const data = await getDocs(artistsCollectionRef);
            setFeaturedArtists(
                data.docs
                    .map(doc => ({
                        ...doc.data(),
                        id: doc.id,
                    }))
                    .sort(() => 0.5 - Math.random())
                    .slice(0, 9) // Affiche les 9 artistes les plus mis en avant
            );
        };

        fetchArtists();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 768) {
                setChunkSize(1);
            } else if (width < 992) {
                setChunkSize(2);
            } else {
                setChunkSize(3);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const chunkArtists = (artists, size) => {
        return artists.reduce((acc, e, i) => {
            const index = Math.floor(i / size);
            if (!acc[index]) acc[index] = [];
            acc[index].push(e);
            return acc;
        }, []);
    };

    return (
        <Container fluid className="featured-artist-container">
            <header className="featured-artist-header text-center mb-4">
                <h2 className="featured-artist-title">Découvrez d'Autres Artistes</h2>
                <p className="featured-artist-text">
                    Laissez-vous inspirer par les talents remarquables qui illuminent l'univers de l'opéra et de la
                    musique classique.
                </p>
            </header>
            <Carousel fade interval={5000}>
                {chunkArtists(featuredArtists, chunkSize).map((group, idx) => (
                    <Carousel.Item key={idx} className="featured-artist-carousel-item">
                        <div className="d-flex justify-content-center flex-wrap">
                            {group.map(artist => (
                                <Card key={artist.id} className="featured-artist-card mx-3">
                                    <Card.Img
                                        variant="top"
                                        src={artist.imageUrl}
                                        alt={artist.name}
                                        className="featured-artist-image"
                                    />
                                    <Card.Body className="text-center">
                                        <Card.Title className="featured-artist-name">{artist.name}</Card.Title>
                                        <Card.Text className="featured-artist-role">
                                            {artist.role} – {artist.specialty || 'Musicien'}
                                        </Card.Text>
                                        <Card.Text className="featured-artist-bio">
                                            {artist.bio
                                                ? `${artist.bio.substring(0, 100)}...`
                                                : "Découvrez l'univers artistique unique de cet interprète."}
                                        </Card.Text>
                                        <div className="featured-artist-social-links">
                                            {artist.socialLinks?.website && (
                                                <a
                                                    href={artist.socialLinks.website}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="featured-artist-social-icon"
                                                    title="Site officiel"
                                                >
                                                    <FaGlobeAmericas />
                                                </a>
                                            )}
                                            {artist.socialLinks?.instagram && (
                                                <a
                                                    href={artist.socialLinks.instagram}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="featured-artist-social-icon"
                                                    title="Instagram"
                                                >
                                                    <FaInstagramSquare />
                                                </a>
                                            )}
                                            {artist.socialLinks?.youtube && (
                                                <a
                                                    href={artist.socialLinks.youtube}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="featured-artist-social-icon"
                                                    title="YouTube"
                                                >
                                                    <FaYoutubeSquare />
                                                </a>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className="text-center mt-4">
                <Button as={Link} to="/artists" className="featured-artist-button">
                    Voir tous les artistes
                </Button>
            </div>
        </Container>
    );
};

export default FeaturedArtists;
