import React, { useRef, useEffect, useState } from 'react';
import './YouTubeVideos.css';

function YouTubeVideos() {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Déconnecte l'observer après la première apparition
          }
        });
      },
      { threshold: 0.3 } // Ajuster le seuil selon le moment de déclenchement souhaité
    );

    // Capturer la référence actuelle une fois pour l'utiliser dans cleanup
    const currentRef = containerRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if(currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const videos = [
    {
      src: "https://www.youtube.com/embed/LjE6f4AmKP0",
      title: `Xavier Flabat - Air de Don Jose "La fleur que tu m'avais jetée" - Carmen (G. Bizet)`,
    },
    {
      src: "https://www.youtube.com/embed/Vb11s79mlig",
      title: `Petite Messe Solennelle de Rossini - "Domine Deus"`,
    },
    {
      src: "https://www.youtube.com/embed/8faH--qFpQU",
      title: `Légende de Kleinzach - Les Contes d'Hoffmann (Offenbach) "Il était une fois à la cour d'Eisenach"`,
    },
    {
      src: "https://www.youtube.com/embed/T5ReEStUodI",
      title: `Les Mousquetaires au couvent (L. Varney) - Trio "Une femme"`,
    },
  ];

  return (
    <section className="youtube-section">
      <h2 className="youtube-section-title">Performances sur YouTube</h2>
      <p className="youtube-introduction">
        Découvrez les interprétations captivantes de Xavier Flabat sur scène,
        disponibles sur sa chaîne YouTube. Plongez dans l'univers lyrique de ce
        ténor exceptionnel et vivez l'opéra comme si vous y étiez.
      </p>
      <div
        className={`youtube-videos-container ${isVisible ? 'visible' : ''}`}
        ref={containerRef}
      >
        {videos.map((video, index) => (
          <div className="youtube-video-card" key={index}>
            <iframe
              src={video.src}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <h3>{video.title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
}

export default YouTubeVideos;
