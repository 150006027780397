import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, deleteDoc, doc, addDoc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { Button, Form, Modal, Alert, Tabs, Tab } from 'react-bootstrap';
import './PerformanceList.css';

const PerformanceList = () => {
  const [performances, setPerformances] = useState([]);
  const { userProfile } = useAuth();
  const isAdmin = userProfile && userProfile.role === 'admin';
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  const [activeTab, setActiveTab] = useState('upcoming'); // Onglet par défaut

  const [formData, setFormData] = useState({
    image: '',
    title: '',
    dates: '',
    location: '',
    director: '',
    conductor: '',
    startDate: '', // Utilisé pour classer automatiquement
  });

  useEffect(() => {
    const fetchPerformances = async () => {
      const snapshot = await getDocs(collection(db, 'performances'));
      const performancesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPerformances(performancesData);
    };

    fetchPerformances();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette performance ?')) {
      await deleteDoc(doc(db, 'performances', id));
      setPerformances(performances.filter(performance => performance.id !== id));
      setAlert({ show: true, message: 'Performance supprimée avec succès.', variant: 'success' });
    }
  };

  const handleAddPerformance = async () => {
    if (!formData.title || !formData.dates || !formData.location || !formData.startDate) {
      setAlert({ show: true, message: 'Veuillez remplir tous les champs requis.', variant: 'danger' });
      return;
    }

    try {
      await addDoc(collection(db, 'performances'), formData);
      setPerformances([...performances, formData]);
      setAlert({ show: true, message: 'Performance ajoutée avec succès.', variant: 'success' });
      setFormData({
        image: '',
        title: '',
        dates: '',
        location: '',
        director: '',
        conductor: '',
        startDate: '',
      });
      setShowModal(false);
    } catch (error) {
      setAlert({ show: true, message: 'Erreur lors de l’ajout de la performance.', variant: 'danger' });
    }
  };

  const currentDate = new Date();

  // Performances passées et à venir
  const pastPerformances = performances.filter(performance => new Date(performance.startDate) < currentDate);
  const upcomingPerformances = performances.filter(performance => new Date(performance.startDate) >= currentDate);

  return (
    <div className="performance-list-container">
      <div className="performance-list-introduction">
        <h1 className="performance-list-title">Calendrier des Performances</h1>
        <p className="performance-list-description">
          Découvrez les performances passées et à venir organisées par saison.
        </p>
      </div>

      {alert.show && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert({ show: false, message: '', variant: '' })}
          dismissible
          className="performance-list-alert"
        >
          {alert.message}
        </Alert>
      )}

      {isAdmin && (
        <div className="performance-list-add-button">
          <Button variant="primary" onClick={() => setShowModal(true)}>
            Ajouter une Performance
          </Button>
        </div>
      )}

      {/* Onglets dynamiques */}
      <Tabs
        id="performance-tabs"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="performance-list-tabs"
      >
        <Tab eventKey="upcoming" title="Performances à venir">
          <div className="performance-list-upcoming">
            {upcomingPerformances.map((performance) => (
              <div key={performance.id} className="performance-list-item">
                <div className="performance-list-item-image">
                  {performance.image && <img src={performance.image} alt={performance.title} />}
                </div>
                <div className="performance-list-item-details">
                  <h3 className="performance-list-item-title">{performance.title}</h3>
                  <p className="performance-list-item-dates">
                    <strong>Dates :</strong> {performance.dates}
                  </p>
                  <p className="performance-list-item-location">
                    <strong>Lieu :</strong> {performance.location}
                  </p>
                  <p className="performance-list-item-director">
                    <strong>Metteur en scène :</strong> {performance.director}
                  </p>
                  <p className="performance-list-item-conductor">
                    <strong>Chef d'orchestre :</strong> {performance.conductor}
                  </p>
                  {isAdmin && (
                    <Button
                      variant="danger"
                      className="performance-list-item-delete-button"
                      onClick={() => handleDelete(performance.id)}
                    >
                      Supprimer
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Tab>
        <Tab eventKey="past" title="Performances passées">
          <div className="performance-list-past">
            {pastPerformances.map((performance) => (
              <div key={performance.id} className="performance-list-item">
                <div className="performance-list-item-image">
                  {performance.image && <img src={performance.image} alt={performance.title} />}
                </div>
                <div className="performance-list-item-details">
                  <h3 className="performance-list-item-title">{performance.title}</h3>
                  <p className="performance-list-item-dates">
                    <strong>Dates :</strong> {performance.dates}
                  </p>
                  <p className="performance-list-item-location">
                    <strong>Lieu :</strong> {performance.location}
                  </p>
                  <p className="performance-list-item-director">
                    <strong>Metteur en scène :</strong> {performance.director}
                  </p>
                  <p className="performance-list-item-conductor">
                    <strong>Chef d'orchestre :</strong> {performance.conductor}
                  </p>
                  {isAdmin && (
                    <Button
                      variant="danger"
                      className="performance-list-item-delete-button"
                      onClick={() => handleDelete(performance.id)}
                    >
                      Supprimer
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Tab>
      </Tabs>

      <Modal show={showModal} onHide={() => setShowModal(false)} className="performance-list-modal">
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une Performance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="image" className="performance-list-form-group">
              <Form.Label>Image (URL)</Form.Label>
              <Form.Control
                type="text"
                placeholder="URL de l'image"
                value={formData.image}
                onChange={(e) => setFormData({ ...formData, image: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="title" className="performance-list-form-group">
              <Form.Label>Nom de la pièce</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom de la pièce"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="dates" className="performance-list-form-group">
              <Form.Label>Dates des représentations</Form.Label>
              <Form.Control
                type="text"
                placeholder="Exemple : Sep 26, 29, 2024"
                value={formData.dates}
                onChange={(e) => setFormData({ ...formData, dates: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="location" className="performance-list-form-group">
              <Form.Label>Lieu</Form.Label>
              <Form.Control
                type="text"
                placeholder="Exemple : Deutsche Oper Berlin, Berlin, Allemagne"
                value={formData.location}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="director" className="performance-list-form-group">
              <Form.Label>Metteur en scène</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom du metteur en scène"
                value={formData.director}
                onChange={(e) => setFormData({ ...formData, director: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="conductor" className="performance-list-form-group">
              <Form.Label>Chef d'orchestre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom du chef d'orchestre"
                value={formData.conductor}
                onChange={(e) => setFormData({ ...formData, conductor: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="startDate" className="performance-list-form-group">
              <Form.Label>Date de début</Form.Label>
              <Form.Control
                type="date"
                value={formData.startDate}
                onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleAddPerformance}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PerformanceList;
